<template>
  <div class="">
    <!-- Header -->
    <div class="header py-5">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-dark">
                <img src="img/logo.png" height="100px"/>
              </h1>
              <p class="text-lead text-dark">Faça o login ou cadastre-se para acessar sua conta.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(doLogin)">
                  <base-input alternative
                              class="mb-3"
                              name="Usuário"
                              :rules="{required: true, min: 4, max: 50, regex: /^[a-z0-9]+(\.?[a-z0-9])+$/}"
                              :oninput="usernameFormalize()"
                              prepend-icon="fas fa-at"
                              placeholder="Usuário"
                              v-model="form.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Senha"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Senha"
                              v-model="form.password">
                  </base-input>

                  <vue-recaptcha
                    ref="recaptcha"
                    :loadRecaptchaScript="true"
                    badge="bottomleft"
                    size="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    sitekey="6LcL_7kZAAAAAKvH8isNe9m69iPxtOKkTVovNcrX">
                  </vue-recaptcha>

                  <base-checkbox v-model="form.rememberMe">Lembrar-me</base-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">ENTRAR</base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/forgot" class="text-primary"><small>Esqueci minha senha</small></router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-primary"><small>Cadastrar</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {failWs, postWs, signin} from '../../ws.service'

  export default {
    components: {
      VueRecaptcha
    },
    data() {
      return {
        showSubmit: true,
        form: {
          username: '',
          password: '',
          rememberMe: false
        }
      };
    },
    mounted() {
      if (localStorage.getItem('isAuth') == 'true') {
        this.$router.push('/dashboard')
      }
      if (window.location.href.includes('olimpopay.com.br')
        && location.protocol != 'https:') {
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
        return
      }
      if (this.$route.query.fail == 1) {
        this.$swal({
          title: 'Serviço temporariamente indisponível!',
          text: 'Tente novamente mais tarde.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          },
          timer: 5000,
          icon: 'error'
        })
      } else if (this.$route.query.msg == 1) {
        postWs("/auth/validate-email", false,
          {hash: this.$route.query.code}, null,
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'E-mail confirmado! Agora é só acessar utilizando o usuário e senha cadastrados.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'success'
            })
          }, () => {
          })
      }
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      doLogin() {
        localStorage.setItem('isAuth', false)
        this.showSubmit = false
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified(recaptchaToken) {
        signin(this.form.username, this.form.password, recaptchaToken,
          this.loginSuccess, this.signinFail)
      },
      signinFail(error) {
        this.showSubmit = true
        let title, text, icon, btnClass
        if ('CREDENTIAL' == error.response.data.message) {
          title = ''
          text = 'Usuário e/ou senha incorretos!'
          icon = 'warning'
          btnClass = 'warning'
        } else if ('EMAIL_NOT_VALIDATED' == error.response.data.message) {
          title = 'E-mail não confirmado'
          text = 'Foi enviado um e-mail de confirmação para você. Para prosseguir abra o e-mail e clique no botão \'Confirmar e-mail\'.'
          icon = 'warning'
          btnClass = 'waring'
        } else if ('DISABLED' == error.response.data.message) {
          title = 'Atenção'
          text = 'O Usuário esta inativo!'
          icon = 'error'
          btnClass = 'danger'
        } else if ('LOCKED' == error.response.data.message) {
          title = 'Atenção'
          text = 'Usuário bloqueado! Favor entrar em contato.'
          icon = 'error'
          btnClass = 'danger'
        } else {
          failWs(error)
          return
        }
        this.$swal({
          title: title,
          text: text,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-' + btnClass + ' btn-fill'
          },
          icon: icon
        })
      },
      loginSuccess(response) {
        localStorage.setItem('isAuth', true)
        location.reload()
      },
      usernameFormalize() {
        this.form.username = this.form.username.replace(' ', '').toLowerCase()
      },
      getError(fieldName) {
        this.buttonColorError()
        return this.errors.first(fieldName)
      },
      buttonColorError() {
        let els = document.getElementsByName("submit")
        if (els.length > 0) {
          if (this.errors.count() == 0) {
            els[0].classList.add("btn-success")
            els[0].classList.remove("btn-danger")
          } else {
            els[0].classList.add("btn-danger")
            els[0].classList.remove("btn-success")
          }
        }
      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset()
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  };
</script>
